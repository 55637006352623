<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='3' >

    <div class="w" v-if="detail.objectId">
      <!-- 标题开始 -->
      <div class="detail-title w flex justify-center items-center">
        <div class="small-w text-white wow fadeInDown">
          <div class="text-17">{{detail.createdAt | YMD}}</div>
          <div class="font-normal text-32">{{detail.name}}</div>
        </div>
      </div>
      <!-- 标题结束 -->

      <!-- 招牌产品开始 -->
      <div class="detail w flex flex-col justify-center items-center" >
        <div class="detail-img wow fadeIn" :style="{backgroundImage: 'url(' + detail.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
        <div class="details small-w color-blue" v-html="detail.details"></div>
        <div class="detail-back small-w flex text-red items-center cursor-pointer pc-show">
          <div class="icon-more">
            <iconFont class="icon" icon='leftarrow' color='#CA4938' :size='18' ></iconFont>
          </div>
          <div class="ml-20" @click="$back()">返回新闻列表</div>
        </div>
      </div>
      <!-- 招牌产品结束 -->

    </div>
  </Containers>
</template>

<script>
import iconFont from '@/components/iconFont'
import { WOW } from 'wowjs'

export default {
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'keywords',
          content: '双美豆沙牛乳新闻资讯详情_双美豆沙牛乳官网'
        },
        {
          name: 'description',
          content: '本页面为您提供双美豆沙牛乳新闻资讯，包含最新新品信息、最新店面信息、开店技巧、运营技巧、联名活动等双美豆沙牛乳品牌各方面信息'
        }
      ]
    }
  },
  data () {
    return {
      status: 'success',
      detail: {},
      title: ''
    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    iconFont,
  },
  computed: {
  },
  methods: {
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)
    },
    async getInit () {
      try {
        // this.status = 'waiting'
        await this.getDetail()
        // this.status = 'requestEnd'
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
    async getDetail () {
      try {
        let detail = await this.$axios.get(`/classes/newsList/${this.$route.query.id}`)
        this.detail = detail
        this.title = detail.name
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

@mixin smalls {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin img {
  @media screen and (max-width: 1050px) {
    @content
  }
}

.small-w{
  width: 800px;
  @include smalls {
    width: 100% !important;
  }
}
.text-17{
  @include hamburger {
    font-size: 14px;
  }
}
.text-32{
  @include hamburger {
    font-size: 18px;
  }
}

.detail-title{
  padding: 210px 0 299px;
  @include hamburger {
    padding: 70px 30px 80px;
  }
}
.detail{
  background-color: #FCFAF0;
  @include hamburger {
    padding: 0 12px;
  }
  .detail-img{
    width: 1028px;
    height: 576px;
    margin: 0 auto;
    margin-top: -249px;
    @include img {
      width: 100%;
    }
    @include hamburger {
      width: 100%;
      height: 179px;
      margin-top: -68px;
    }
  }
  .details{
    margin-top: 50px;
    @include hamburger {
      margin-top: 20px;
    }
  }
  .detail-back{
    border-top: 1px solid rgba(71, 102, 133, 0.1);
    margin-top: 80px;
    padding: 50px 0 80px;
  }
}
.icon-more{
  width: 20px;
  height: 20px;
  border: 1.5px solid #CA4938;
  .icon{
    left: unset;
    right: -8px;
  }
}

</style>
